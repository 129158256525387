import React, { useEffect, useContext } from 'react';
import styled, { css } from 'styled-components';
import { graphql } from 'gatsby';
import { Link } from 'gatsby';
import flatten from 'lodash.flatten';
import Layout from 'components/layout';
import SEO from 'components/seo';
import { device } from 'components/device';
import { FeaturedMarketing } from 'components/CmsComponents/FeaturedMarketing';
import { createDoctorData } from 'utils/createDoctorData';
import { TmpHeaderBase } from '@tempo/tempo-design-system-core/dist';
import Components from 'components/CmsComponents/';
import { HeaderContext } from 'context/header';

// Context
import { DoctorContext } from 'context/doctor';

// Imagens
import Logo from 'images/logos/logo_hsl.svg';

const HeaderWrapper = styled.div`
  width: 100vw;
  grid-area: HD;
`;

const BrandingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: calc(-170px + 24px);

  gap: 48px;

  img {
    height: auto;
    :first-child {
      width: 10.625rem;
      @media ${device.mobile} {
        width: 9.063rem;
        margin-left: 24px;
      }
    }
  }
`;

const ColorfulMonth = ({
  data: {
    gcms: { colorfulMonths, specialty, doctors },
    doctorsApi,
  },
}) => {
  const {
    setMainMarginTopLaptop,
    setMainMarginTopMobile,
    showAlertPostFeriado,
    isVisibleNovoSite,
  } = useContext(HeaderContext);
  const page = colorfulMonths?.[0];
  const featuredMarketingContent = page?.featuredMarketing;
  const components = page?.components ?? [];
  const specialtyDoctors = page?.specialty?.doctor || specialty?.doctor;
  const apiDoctors = doctorsApi?.doctors;
  const isWindow = typeof window !== 'undefined';

  const { setRenderList, setDoctorsList, setDoctorsOncology } = useContext(
    DoctorContext
  );

  const formattedDoctors = specialtyDoctors
    .map(doctor =>
      createDoctorData({
        doctorCMS: doctor,
        doctorsAPI: apiDoctors,
      })
    )
    ?.filter(e => e)
    .sort(
      (a, b) =>
        (a.name.trim().toLowerCase() > b.name.trim().toLowerCase() && 1) || -1
    )
    .sort((a, b) => b.employee - a.employee);

  const dataSeo = [
    {
      seo: {
        metaTitle: page?.seo?.metaTitle ?? '',
        metaDescription: page?.seo?.metaDescription ?? '',
      },
    },
  ];

  const HeaderReplace = () => {
    return (
      <HeaderWrapper>
        {isWindow && { TmpHeaderBase } && (
          <TmpHeaderBase
            children={
              <BrandingWrapper>
                {page?.headerSimplificado && (
                  <a href="https://hospitalsiriolibanes.org.br/">
                    <img src={Logo} alt="Sírio-Libanês"></img>
                  </a>
                )}
              </BrandingWrapper>
            }
          />
        )}
      </HeaderWrapper>
    );
  };

  useEffect(() => {
    setDoctorsOncology(formattedDoctors);
    setDoctorsList(formattedDoctors);
  }, []);

  useEffect(() => {
    setRenderList('all');
  }, []);

  useEffect(() => {
    if (showAlertPostFeriado || isVisibleNovoSite) {
      setMainMarginTopLaptop('9.075rem');
      setMainMarginTopMobile('7.875rem');
    } else {
      setMainMarginTopLaptop('7.063rem');
      if (!colorfulMonths[0]?.headerSimplificado) {
        setMainMarginTopMobile('5.875rem');
      } else {
        setMainMarginTopMobile('0');
      }
    }
  }, [showAlertPostFeriado, isVisibleNovoSite]);

  return (
    <Layout
      hideHeader={!!colorfulMonths[0]?.headerSimplificado}
      headerReplace={<HeaderReplace />}
    >
      <SEO
        dataSeo={dataSeo}
        image={page?.featuredMarketing?.images?.[0]?.url ?? ''}
      />
      {featuredMarketingContent && (
        <FeaturedMarketing content={featuredMarketingContent} />
      )}

      {components.length >= 1 &&
        components?.map(component => {
          return Components(component);
        })}
    </Layout>
  );
};

export const ColorfulMonthPageQuery = graphql`
  query ColorfulMonthPageQuery($id: ID) {
    doctorsApi {
      doctors {
        id
        name
        gender
        crm
        email
        phone
        phonecommercial
        specialty
        employee
        estab
        state
      }
    }
    gcms {
      doctors(first: 1000) {
        active
        id
        id_api
      }
      specialty(
        locales: [pt_BR, en]
        where: { id: "cl8egypeli8db0gn0nbnzllkp" }
      ) {
        doctor(first: 1000) {
          id
          id_api
          active
          assignment
          name
          crm
          photo {
            handle
            url
            height
            width
          }
          phonecommercial
          state
          employee
          specialties {
            id
            name
          }
        }
      }
      colorfulMonths(locales: [pt_BR, en], where: { id: $id }) {
        headerSimplificado
        id
        name
        slug
        seo {
          id
          metaTitle
          metaDescription
          noIndex
        }
        ancoraLanding
        fragments {
          id
          name
          markdown
          type
        }

        featuredMarketing {
          id
          type
          breadcrumb
          backgroundColor {
            hex
          }
          backgroundInitialColor {
            hex
          }
          backgroundFinalColor {
            hex
          }
          images {
            handle
            width
            height
            url
          }
          title
          fragments {
            id
            name
            type
            markdown
          }
        }
        fragments {
          id
          name
          singletexts
          localizations {
            singletexts
            markdown
          }
          assetpicker {
            handle
            width
            height
            url
          }
          assetpickerMultiple {
            handle
            width
            height
          }
        }
        components {
          __typename
          ... on GraphCMS_SimpleContent {
            id
            name
            type
            title
            fragments {
              id
              name
              type
              markdown
              datafragment
            }
          }
          ... on GraphCMS_HighlightContent {
            id
            name
            type
            backgroundColorInitial {
              hex
            }
            backgroundColorFinal {
              hex
            }
            title
            customTitle
            description
            images {
              handle
              width
              height
              url
            }
            fragments {
              id
              name
              type
              markdown
            }
          }
          ... on GraphCMS_VideoGalleryContent {
            id
            name
            type
            title
            headerLinkTitle
            headerLink
            description
            fragments {
              id
              name
              type
              markdown
            }
          }
          ... on GraphCMS_BannerSpecialtyContent {
            id
            name
            type
            backgroundColor {
              hex
            }
            backgroundColorInitialAlias: backgroundColorInitial {
              hex
            }
            backgroundColorFinalAlias: backgroundColorFinal {
              hex
            }
            title
            description
            images {
              handle
              width
              height
              url
            }
            fragments {
              id
              name
              type
              markdown
            }
          }
          ... on GraphCMS_ListPostContent {
            id
            name
            type
            title
            headerLinkTitle
            headerLink
            posts {
              id
              slugPost
              title
              topic
              author
              doctors(first: 1000, locales: [pt_BR, en]) {
                id
                id_api
                active
                assignment
                name
              }
              assetpicker {
                handle
                height
                width
                url
              }
              categories {
                tag
              }
            }
          }
          ... on GraphCMS_ComponentFormPremioVioleta {
            id
          }
          ... on GraphCMS_DoctorAndSpecialtyContent {
            id
            name
            type
            title
            subtitle
            estado
            images {
              handle
              width
              height
              url
            }
            specialties {
              id
              name
              slug
              customUrl
              unity
            }
            fragments {
              id
              name
              type
              markdown
            }
          }
          ... on GraphCMS_CarouselGalleryContent {
            id
            name
            type
            title
            backgroundColor {
              hex
            }
            images {
              handle
              width
              height
              url
            }
          }
          ... on GraphCMS_ListQAContent {
            id
            name
            title
            subTitle
            type
            isDesignSystem

            anchorTo
            faqLink
            backgroundColor {
              hex
            }
            qaData {
              ... on GraphCMS_CustomFragment {
                id
                name
                markdown
                singletexts
              }
              ... on GraphCMS_Qa {
                id
                autor
                pergunta
                titulo
                doctors(first: 1000) {
                  id
                  id_api
                  active
                  assignment
                  name
                }
              }
            }
          }
          ... on GraphCMS_HighlightFormLeadContent {
            id
            name
            type
            backgroundColorInitial {
              hex
            }
            backgroundColorFinal {
              hex
            }
            title
            customTitle
            description
            referenceToSalesforce
            images {
              handle
              width
              height
              url
            }
            documentsForDownload {
              handle
              width
              height
              url
            }
            footnote
          }
        }
        specialty {
          doctor {
            active
            id
            assignment
            crm
            employee
            id_api
            name
            phonecommercial
            state
            photo {
              handle
              height
              url
              width
            }
            specialties {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export default ColorfulMonth;
